<label
  [formGroup]="formGroup()"
  class="at-pointer at-position-relative at-d-flex at-align-items-center"
  [class]="inputClass()"
  [for]="id()"
  ecommerceClickOutSide
  (clickOutside)="clickedOutside()"
  [style.cursor]="disabled() ? 'not-allowed' : ''"
  [ngClass]="{
    'at-border-bottom':
      inputStyleType() === 'has-background' ||
      inputStyleType() === 'bottom-border',
    'at-border-secondary4':
      (inputStyleType() === 'has-background' ||
        inputStyleType() === 'bottom-border' ||
        inputStyleType() === 'full-border') &&
      !disabled() &&
      !errorMessage(),
    'has-background': inputStyleType() === 'has-background',
    'at-border at-rounded-2': inputStyleType() === 'full-border',
    focused: label() && (focused || notEmpty) && !disabled(),
    'default at-py-4 at-px-2': inputSize() === 'default',
    'small at-p-2': inputSize() === 'small',
    'at-border-failure': errorMessage(),
    'disabled at-border-secondary3': disabled(),
  }">
  @if (selectedOption && selectedOption['flag']) {
    <label
      class="at-d-flex at-pointer at-position-relative at-me-2 flag"
      [style.cursor]="disabled() ? 'not-allowed' : ''"
      [class.at-text-hint]="disabled() && !errorMessage()"
      [class.at-text-failure]="errorMessage()"
      (click)="!disabled() && toggleDropdown()">
      <img
        [src]="selectedOption['flag']"
        [alt]="label()"
        width="16"
        height="16" />
    </label>
  }

  <div class="dropdown at-position-relative at-w-100">
    <div
      class="at-d-flex at-justify-between"
      [style.cursor]="disabled() ? 'not-allowed' : 'pointer'">
      <label
        class="at-border label-value at-paragraph-regular"
        [style.cursor]="disabled() ? 'not-allowed' : ''"
        [class.at-text-hint]="disabled() && !errorMessage()"
        [class.at-text-failure]="errorMessage()"
        (click)="!disabled() && toggleDropdown()">
        {{ selectedValue }}
      </label>

      <input
        (click)="!disabled() && toggleDropdown()"
        [formControlName]="inputFormControlName()"
        type="text"
        [id]="id()"
        class="hidden-input"
        [value]="selectedValue"
        (focus)="focused = true"
        (blur)="focused = false"
        [class.disabled]="disabled()"
        [readOnly]="true" />

      <img
        [src]="
          inputStyleType() === 'has-background'
            ? 'assets/images/form/arrow-down-filled.svg'
            : 'assets/images/svg/arrow-down.svg'
        "
        [style.cursor]="disabled() ? 'not-allowed' : ''"
        [height]="inputStyleType() === 'has-background' ? '24' : '16'"
        [width]="inputStyleType() === 'has-background' ? '24' : '16'"
        alt="arrow down icon"
        loading="lazy" />
    </div>
  </div>

  @if (isDropdownOpen) {
    <div class="dropdown-list at-shadow-xlarge">
      @if (hasSearch()) {
        <input
          class="dropdown-search at-w-100 at-px-2 at-py-3"
          type="search"
          [placeholder]="searchPlaceholder()"
          (input)="onDropdownSearch($event)" />
      }
      @if (!showNoSearchResult) {
        @for (
          option of filteredOptions.length ? filteredOptions : options();
          track option
        ) {
          <div
            [ngClass]="{ 'disabled-option': option.disabled }"
            class="dropdown-item"
            (click)="selectOption(option, true)">
            @if (option) {
              @if (option['flag']) {
                <img
                  [src]="option['flag']"
                  [alt]="label()"
                  width="16"
                  height="16"
                  class="at-me-2" />
              }
              @if (
                option['calling_code'] && selectedKeyName() === 'calling_code'
              ) {
                {{ option['calling_code'] }}
              }
              @if (option['name']) {
                {{ option['name'] }}
              }
            }
          </div>
        }
      } @else {
        <div class="at-p-2 at-text-hint">
          {{ noSearchResultLabel() }}
        </div>
      }
    </div>
  }

  @if (label()) {
    <label
      [id]="id() + '_label'"
      class="label at-pointer"
      [style.cursor]="disabled() ? 'not-allowed' : ''"
      [class.at-text-hint]="!disabled() && !errorMessage()"
      [class.at-text-secondary4]="disabled() && !errorMessage()"
      [class.at-text-failure]="errorMessage()"
      [class.at-paragraph-extra-small]="
        label() && (focused || notEmpty) && !disabled()
      "
      [class.at-bg-white]="
        !(
          (disabled() || !focused || !notEmpty) &&
          inputStyleType() !== 'full-border'
        )
      "
      [class.at-bg-transparent]="
        (disabled() ||
          !focused ||
          !notEmpty ||
          !removeLabelWhenSelectValue()) &&
        inputStyleType() !== 'full-border'
      "
      [class.at-d-none]="selectedValue && removeLabelWhenSelectValue()"
      [for]="id()"
      [class.start-icon-label]="startIcon()">
      {{ label() + (required() ? ' *' : '') }}
    </label>
  }
</label>

@if (hintMessage() && !errorMessage()) {
  <div
    class="at-paragraph-extra-small at-text-hint at-mt-1 at-ms-2 at-text-start"
    [class.at-paragraph-secondary3]="disabled()">
    {{ hintMessage() }}
  </div>
}

@if (errorMessage()) {
  <div
    class="at-paragraph-extra-small at-text-failure at-mt-1 at-ms-2 at-text-start"
    [class.at-paragraph-secondary3]="disabled()">
    {{ errorMessage() }}
  </div>
}
