<button
  [type]="type()"
  class="btn"
  [ngClass]="btnClass()"
  [disabled]="disabled()"
  [id]="id()">
  @if (startIcon()) {
    <img [src]="startIcon()?.src" [alt]="startIcon()?.alt" />
  }

  @if (label() && !loading()) {
    <span [ngClass]="{ 'at-ps-2': startIcon(), 'at-pe-2': endIcon() }">
      {{ label() }}
    </span>
  } @else {
    <div class="spinner">
      <ecommerce-spinner [isSmall]="true" [isDark]="true"></ecommerce-spinner>
    </div>
  }

  @if (endIcon()) {
    <img [src]="endIcon()?.src" [alt]="endIcon()?.alt" />
  }
</button>
